export default {
  name: 'SeriesCard',
  components: {},
  props: {
    series: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {}
};
