var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('Flicking',{ref:"flicking",attrs:{"options":{
      circular: true,
      perPage: 1,
      bound: true,
      loop: true,
      autoResize: true,
      moveType: [_vm.MOVE_TYPE.SNAP, { count: 0 }]
    }}},_vm._l((_vm.list),function(banner){return _c('div',{key:banner.id},[_c('a',{staticClass:"banner-wrap",attrs:{"href":banner.url,"target":"_blank"}},[_c('div',{staticClass:"banner-source"},[(_vm.screenWidth > 900)?_c('img',{attrs:{"src":banner.image,"alt":"banner-image"}}):_c('img',{attrs:{"src":banner.mobileImage,"alt":"banner-image"}})])])])}),0),_c('div',{staticClass:"banner-prev",on:{"click":_vm.prevSlide}},[_c('svg',{attrs:{"width":"13","height":"25","viewBox":"0 0 13 25","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('line',{attrs:{"x1":"12.3536","y1":"0.853553","x2":"0.353554","y2":"12.8536","stroke":"black"}}),_c('line',{attrs:{"y1":"-0.5","x2":"16.9706","y2":"-0.5","transform":"matrix(-0.707107 -0.707107 -0.707107 0.707107 12 24.5)","stroke":"black"}})])]),_c('div',{staticClass:"banner-next",on:{"click":_vm.nextSlide}},[_c('svg',{attrs:{"width":"13","height":"25","viewBox":"0 0 13 25","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('line',{attrs:{"y1":"-0.5","x2":"16.9706","y2":"-0.5","transform":"matrix(0.707107 0.707107 0.707107 -0.707107 1 0.5)","stroke":"black"}}),_c('line',{attrs:{"x1":"0.646447","y1":"24.1464","x2":"12.6464","y2":"12.1464","stroke":"black"}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }